(function() {
    'use strict';

    angular.module('rolesConfig')
        .service('rbacService', ['$injector',
            function ($injector) {
                var $scope;
                var allPermissions;
                this.getScope = function (scope) {
                    $scope = scope;
                };

                this.setAllPermissions = function (ap) {
                    allPermissions = ap;
                };

                this.setAllPermissionsPromise = function (promise) {
                    promise.then(function(perm){allPermissions = perm;});
                };

                this.checkAccess = function (request) {

                    var adminPermissions = allPermissions.adminPermissions;
                    var permissions = allPermissions.permissions;
                    var accountPermissions = allPermissions.accountPermissions;

                    var accountAccess = false;

                    function checkAccount (roles) {
                        _.each(roles, function (role) {
                            if (accountPermissions[request] !== undefined
                                && accountPermissions[request][role] !== undefined
                                && accountPermissions[request][role].indexOf(role) > -1) { accountAccess = true; }
                        });
                    }

                    if (typeof accountPermissions[request] !== "undefined") {
                        checkAccount($scope.accountRoles);
                        if (!accountAccess || typeof permissions[request] === "undefined") {
                            return accountAccess;
                        }
                    }


                    var roleAccess = false;
                    var level = "organization";
                    function checkRoles (roles) {
                        _.each(roles, function (role) {
                            if (permissions[request] !== undefined && permissions[request][level].indexOf(role) > -1) { roleAccess = true;}
                        });
                    }
                    if ($scope.$parent &&
                        $scope.$parent.roles &&
                        typeof $scope.$parent.roles !== "undefined") {
                        checkRoles($scope.$parent.roles);
                        level = "project";
                    }
                    checkRoles($scope.roles);
                    return roleAccess;
                };

                this.checkMfgAccess = function (request) {
                    var adminPermissions = allPermissions.adminPermissions;
                    var roleAccess = false;

                    function checkRoles (roles) {
                        _.each(roles, function (role) {
                            if (adminPermissions[request] !== undefined && adminPermissions[request]["mfg"] && adminPermissions[request]["mfg"].indexOf(role) > -1) { roleAccess = true;}
                        });
                    }
                    checkRoles($scope.me.roles);
                    return roleAccess;
                };

                this.checkLinkAccess = function (request) {
                    var adminPermissions = allPermissions.adminPermissions;
                    var roleAccess = false;

                    function checkRoles (roles) {
                        _.each(roles, function (role) {
                            if (adminPermissions[request] !== undefined && adminPermissions[request]["link"] && adminPermissions[request]["link"].indexOf(role) > -1) { roleAccess = true;}
                        });
                    }
                    checkRoles($scope.me.roles);
                    return roleAccess;
                };

                this.checkAdminAccess = function (request) {
                    var adminPermissions = allPermissions.adminPermissions;
                    var roleAccess = false;

                    function checkRoles (roles) {
                        _.each(roles, function (role) {
                            if (adminPermissions[request] !== undefined && adminPermissions[request]["admin"].indexOf(role) > -1) { roleAccess = true; }
                        });
                    }
                    checkRoles($scope.me.roles);
                    return roleAccess;

                };

                function canViewPortalType(type, roles) {
                    var adminPermissions = allPermissions.adminPermissions;
                    var roleAccess = false;
                    function checkRoles (permission, roles) {
                        _.each(roles, function (role) {
                            if (permission !== undefined && permission.indexOf(role) > -1) {
                                roleAccess = true;
                            }
                        });
                    }
                    _.each(adminPermissions, function(permission) {
                        checkRoles(permission[type], roles);
                    });
                    return roleAccess;
                }

                this.canViewMfgPortal = function(roles) {
                    return canViewPortalType("mfg", roles);
                };

                this.canViewAdminPortal = function(roles) {
                    return canViewPortalType("admin", roles);
                };

            }
        ]);
})();
